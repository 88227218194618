import React from 'react'
import styled from 'styled-components'

import { ClinicThumb } from 'components/blocks/sections/ClinicThumb'
import { Container } from 'components/blocks/sections/directories/AllEntries/ServiceEntries'
import { Carousel } from 'components/ui/deprecated/Carousel'
import { SelectedClinicsStoryblok } from 'lib/storyblok/types'

type Props = {
  block: SelectedClinicsStoryblok
}

export const SelectedClinics = ({ block, ...rest }: Props): JSX.Element => {
  const { items, variant } = block

  return (
    <Wrapper {...rest}>
      {variant === 'grid' && (
        <Grid>
          {items?.map((clinic) => {
            if (!clinic || typeof clinic === 'string') return

            return (
              <ClinicThumb
                key={clinic.uuid}
                name={clinic.content?.name}
                city={clinic.content?.city}
                postalCode={clinic.content?.postal_code}
                image={clinic.content?.image?.filename}
                phoneNumber={clinic.content?.phone_number}
                contactLink={clinic.content?.contact_page?.cached_url}
                link={
                  clinic.content?.homepage?.cached_url
                    ? clinic.content?.homepage?.cached_url
                    : '/'
                }
              />
            )
          })}
        </Grid>
      )}
      {variant === 'slider' && (
        <Carousel itemsPerView={2}>
          {items?.map((clinic) => {
            if (!clinic || typeof clinic === 'string') return

            return (
              <ClinicThumb
                key={clinic.uuid}
                name={clinic.content?.name}
                city={clinic.content?.city}
                postalCode={clinic.content?.postal_code}
                image={clinic.content?.image?.filename}
                phoneNumber={clinic.content?.phone_number}
                contactLink={clinic.content?.contact_page?.cached_url}
                link={
                  clinic.content?.homepage?.cached_url
                    ? `/${clinic.content?.homepage?.cached_url}`
                    : '/'
                }
              />
            )
          })}
        </Carousel>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${({ theme }) => theme.media.lg} {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
`

const Grid = styled(Container)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: repeat(2, 1fr);
  }
`
